body {
  background-color: #d03469;
  color: white;
}

.App {
  font-family: sans-serif;
}
.App > h1,
.App > form {
  text-align: center;
  position: relative;
  z-index: 2;
}

.top {
  position: relative;
  text-align: center;
  z-index: 999;
  height: 300px;
}

.lists {
  z-index: 1;
  position: fixed;
  top: 300px;
  left: 0;
  right: 0;
  bottom: 0;
}
.list {
  padding: 20px 1em;
}
.list .simplebar-content > div {
  margin: 1em;
}

/*.list .simplebar-content {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;

  justify-content: space-between;
}*/

.lists .MuiCircularProgress-root {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 999;
}
.shadow-top {
  position: relative;
  width: 100%;
  height: 1px;
  box-shadow: 0px 0px 20px 80px rgb(208, 52, 105);
  z-index: 1;
  top: 0;
}
.shadow-bottom {
  position: relative;
  width: 100%;
  height: 1px;
  box-shadow: 0px 0px 20px 80px rgb(208, 52, 105);
  z-index: 1;
  top: 0;
}
.processing-content .MuiCircularProgress-root {
  margin: auto;
}

button {
  background-color: #ff85b0;
}

.card-wrapper {
  width: 25%;
}

@media only screen and (max-width: 1025px) {
  .card-wrapper {
    width: 33.333333333%;
  }
}

@media only screen and (max-width: 830px) {
  .card-wrapper {
    width: 100%;
  }
}
